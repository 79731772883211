<template>
  <div>
    <b-row class="match-height">
      <b-col cols="12">
        <b-card no-body>
          <b-card-header>
            <b-card-title>
              Danh sách lớp tín chỉ mở đăng ký
              <span
                v-if="!isAllowRegister"
                style="color: red; font-style: italic;"
              >
                (Hết thời gian đăng ký học phần [{{ courseSemesterCurrent.openRegisterAt }} - {{ courseSemesterCurrent.closeRegisterAt }}] )
              </span>
            </b-card-title>
          </b-card-header>
          <div class="m-2">
            <b-row class="match-height">
              <b-col
                cols="9"
                md="4"
              >
                <b-form-group label-for="programmeGroupId">
                  <v-select
                    v-model="filter.programmeGroup"
                    placeholder="Lọc danh sách lớp tín chỉ theo"
                    label="label"
                    :options="optionList"
                    :reduce="option => option.value"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="9"
                md="6"
                class="text-left d-md-flex align-items-md-start justify-content-md-end"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mb-0 mt-0 mb-sm-1"
                  @click="onSearchMainData"
                >
                  <feather-icon icon="FilterIcon" /> Lọc
                </b-button>
              </b-col>
            </b-row>
          </div>

          <b-card-body>
            <vue-good-table
              :columns="registerColumns"
              :rows="dataRows1"
              :pagination-options="paginationOptions"
              :line-numbers="true"
            >
              <div
                slot="emptystate"
                style="text-align: center; font-weight: bold"
              >
                Không có bản ghi nào !
              </div>
              <template
                slot="table-row"
                slot-scope="props"
              >
                <span v-if="props.column.field === 'capacity'">
                  {{ props.row.currentQuantity }} / {{ props.row.maxQuantity }}
                </span>
                <span v-else-if="props.column.field === 'action'">
                  <b-button
                    v-if="isAllowRegister"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="btn-icon"
                    size="sm"
                    :disabled="props.row.currentQuantity >= props.row.maxQuantity"
                    @click="onCreateStudentRegisterSubject(props.row)"
                  >
                    <feather-icon icon="PlusIcon" />
                  </b-button>
                </span>
                <span v-else-if="props.column.field === 'schedule'">
                  <span
                    class="list-schedule"
                    v-html="props.formattedRow[props.column.field]"
                  />
                </span>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </vue-good-table>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col cols="12">
        <b-card no-body>
          <b-card-header>
            <b-card-title>Danh sách lớp tín chỉ đã đăng ký</b-card-title>
          </b-card-header>
          <b-card-body>
            <vue-good-table
              :columns="registeredColumns"
              :rows="dataRows"
              :pagination-options="paginationOptions"
              :line-numbers="true"
            >
              <div
                slot="emptystate"
                style="text-align: center; font-weight: bold"
              >
                Không có bản ghi nào !
              </div>
              <template
                slot="table-row"
                slot-scope="props"
              >
                <span v-if="props.column.field === 'action'">
                  <b-button
                    v-if="props.row.status <= 2"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="danger"
                    class="btn-icon"
                    size="sm"
                    @click="onDeleteStudentRegisterSubject(props.row.studentCreditClassId)"
                  >
                    <feather-icon icon="TrashIcon" />
                  </b-button>
                </span>
                <span v-else-if="props.column.field === 'subjectName'">
                  {{ props.row.subjectName }} [{{ props.row.subjectCode }}]
                </span>
                <span v-else-if="props.column.field === 'schedule'">
                  <span
                    class="list-schedule"
                    v-html="props.formattedRow[props.column.field]"
                  />
                </span>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </vue-good-table>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BButton, BCard, BCardBody, BCardHeader, BCardTitle, BCol, BFormGroup, BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getUser } from '@/auth/utils'
import Ripple from 'vue-ripple-directive'
import { Flag } from '@/const/flag'
import moment from 'moment'
import vSelect from 'vue-select'

export default {
  name: 'RegisterSubject',
  directives: {
    Ripple,
  },
  components: {
    vSelect,
    BFormGroup,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BButton,
    VueGoodTable,
  },
  data() {
    return {
      isLoading: false,
      user: getUser(),
      filter: {
        studentId: null,
        programmeId: null,
        courseSemesterId: null,
        programmeGroup: '',
        studentClassId: '',
      },
      registerColumns: [
        {
          label: 'Học phần',
          field: 'subjectName',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập tên học phần',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Mã lớp độc lập',
          field: 'creditClassCode',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập mã lớp độc lập',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Số lượng đăng ký',
          field: 'capacity',
          thClass: 'text-center',
        },
        {
          label: 'Học kỳ',
          field: 'semester',
          thClass: 'text-center',
        },
        {
          label: 'Lịch học',
          field: 'schedule',
          width: '20%',
          thClass: 'text-center',
        },
        {
          label: 'Thao tác',
          field: 'action',
          width: '10%',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
      registeredColumns: [
        {
          label: 'Học phần',
          field: 'subjectName',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập tên học phần',
          },
          sortable: true,
          thClass: 'text-center',
        },
        // {
        //   label: 'Phòng ban',
        //   field: 'departmentName',
        //   sortable: false,
        //   thClass: 'text-center',
        // },
        {
          label: 'Mã lớp độc lập',
          field: 'creditClassCode',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập mã lớp độc lập',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Học kỳ',
          field: 'semester',
          thClass: 'text-center',
        },
        {
          label: 'Ngày đăng ký',
          field: 'createdAt',
          thClass: 'text-center',
        },
        {
          label: 'Lịch học',
          field: 'schedule',
          width: '20%',
          thClass: 'text-center',
        },
        {
          label: 'Thao tác',
          field: 'action',
          width: '10%',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
      paginationOptions: {
        enabled: false,
      },
      courseSemesterCurrent: [],
      isAllowRegister: false,
      dataRows: [],
      dataRows1: [],
      messages: [],
      socket: null,
      isListening: null,
    }
  },

  computed: {
    ...mapGetters({
      registerDataSources: 'registerSubject/registerDataSources',
      registeredDataSources: 'registerSubject/registeredDataSources',
      courseSemesters: 'courseSemester/dataLists',
      optionList: 'registerSubject/options',
    }),
    studentClassId() {
      const { programmes, portalProgrammeId } = this.user
      if (!portalProgrammeId || programmes.length === 0) return null
      const found = programmes.find(element => element.id === portalProgrammeId)
      return found ? found.studentClassId : null
    },
    courseId() {
      const { programmes, portalProgrammeId } = this.user
      if (!portalProgrammeId || programmes.length === 0) return null
      const found = programmes.find(element => element.id === portalProgrammeId)
      return found ? found.courseId : null
    },
  },
  beforeDestroy() {
    // Hủy kết nối và xóa các lắng nghe sự kiện trước khi component bị hủy
    this.sockets.unsubscribe('response_register_subject_from_socket')
  },
  mounted() {
    // Lắng nghe sự kiện từ server socket
    this.sockets.subscribe('response_register_subject_from_socket', response => {
      const data = JSON.parse(response)
      if (data.status === 1) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: data.message,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: data.message,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }
    })
  },

  async created() {
    // Đăng ký lên server socket
    this.$socket.emit('register_server_socket', `${this.user.accountId}-${this.user.orgCode}`)

    await Promise.all([
      this.getDataSourcesFromStore(),
      this.getCourseSemester({
        courseId: this.courseId,
        status: 1,
        currentPage: 1,
        itemsPerPage: 10,
      }),
    ])
    if (this.courseSemesters.length > 0) {
      // eslint-disable-next-line prefer-destructuring
      this.courseSemesterCurrent = this.courseSemesters[0]
    }

    const openDate = +moment(this.courseSemesterCurrent.openRegisterAt, 'DD/MM/YYYY HH:mm')
    const closeDate = +moment(this.courseSemesterCurrent.closeRegisterAt, 'DD/MM/YYYY HH:mm')
    const intTimeCurren = +moment()
    if (openDate <= intTimeCurren && closeDate >= intTimeCurren) {
      this.isAllowRegister = true
    }
  },
  methods: {
    ...mapActions({
      getDataSources: 'registerSubject/getDataSources',
      checkOverMinCredit: 'registerSubject/checkOverMinCredit',
      createStudentRegisterSubject: 'registerSubject/createStudentRegisterSubject',
      deleteStudentRegisteredSubject: 'registerSubject/deleteStudentRegisteredSubject',
      getCourseSemester: 'courseSemester/getData',
    }),

    async getDataSourcesFromStore() {
      this.isLoading = true
      try {
        const { programmes, portalProgrammeId } = this.user
        const found = programmes.find(element => element.id === portalProgrammeId)
        this.filter.studentId = this.user.studentId
        this.filter.programmeId = this.user.portalProgrammeId
        this.filter.courseSemesterId = found.courseSemesterId
        this.filter.studentClassId = found.studentClassId
        await this.getDataSources(this.filter)
        if (this.registeredDataSources.length > 0) {
          this.registeredDataSources.forEach((item, key) => {
            this.registeredDataSources[key].schedule = ''
            if (item.dayIds) {
              const arrRoomName = item.roomNames.split(',')
              const arrTurnCode = item.turnCodes.split(',')
              const arrDaySIds = item.dayIds.split(',')
              const arrDayNames = item.dayNames.split(',')
              const dayDistinct = Array.from(new Set(arrDaySIds))
              const dayNameDistinct = Array.from(new Set(arrDayNames))
              let roomsText = '<ul>'
              for (let j = 0; j < dayDistinct.length; j++) {
                const itemDay = dayDistinct[j]
                roomsText += `<li>📅 ${dayNameDistinct[j]}`
                for (let k = 0; k < arrDaySIds.length; k++) {
                  if (arrDaySIds[k] === itemDay) {
                    roomsText += `&#9962; ${arrRoomName[k]}`
                    break
                  }
                }
                // eslint-disable-next-line no-plusplus
                roomsText += '⏰ ['
                // eslint-disable-next-line no-plusplus
                for (let k = 0; k < arrDaySIds.length; k++) {
                  if (arrDaySIds[k] === itemDay) {
                    roomsText += `${arrTurnCode[k]},`
                  }
                }
                roomsText += ']'
                roomsText += '</li>'
              }
              roomsText += '</ul>'
              // lọc các day trùng lặp
              // const dayDistinct = Array.from(new Set(item.dayIds))
              this.registeredDataSources[key].schedule = roomsText
            }
          })
        }
        this.dataRows = this.registeredDataSources
        if (this.registerDataSources.length > 0) {
          this.registerDataSources.forEach((item, key) => {
            this.registerDataSources[key].schedule = ''
            if (item.dayIds) {
              const arrRoomName = item.roomNames.split(',')
              // const arrTurnName = item.turnNames.split(',')
              const arrTurnCode = item.turnCodes.split(',')
              // console.log(roomDistinct)
              const arrDaySIds = item.dayIds.split(',')
              const arrDayNames = item.dayNames.split(',')
              const dayDistinct = Array.from(new Set(arrDaySIds))
              const dayNameDistinct = Array.from(new Set(arrDayNames))
              // eslint-disable-next-line no-unused-vars
              let roomsText = '<ul>'
              // eslint-disable-next-line no-plusplus
              for (let j = 0; j < dayDistinct.length; j++) {
                const itemDay = dayDistinct[j]
                roomsText += `<li>📅 ${dayNameDistinct[j]}`
                // eslint-disable-next-line no-plusplus
                for (let k = 0; k < arrDaySIds.length; k++) {
                  if (arrDaySIds[k] === itemDay) {
                    roomsText += `&#9962; ${arrRoomName[k]}`
                    break
                  }
                }
                // eslint-disable-next-line no-plusplus
                roomsText += '⏰ ['
                // eslint-disable-next-line no-plusplus
                for (let k = 0; k < arrDaySIds.length; k++) {
                  if (arrDaySIds[k] === itemDay) {
                    roomsText += `${arrTurnCode[k]},`
                  }
                }
                roomsText += ']'
                roomsText += '</li>'
              }
              roomsText += '</ul>'
              // lọc các day trùng lặp
              // const dayDistinct = Array.from(new Set(item.dayIds))
              this.registerDataSources[key].schedule = roomsText
            }
          })
        }
        this.dataRows1 = this.registerDataSources
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onCreateStudentRegisterSubject(data) {
      // eslint-disable-next-line no-param-reassign
      if (data.replaceThesis === Flag.REPLACE_THESIS) {
        this.$swal
          .fire({
            title: 'Học phần này thuộc nhóm thay thế khóa luận tốt nghiệp',
            text: 'Bạn muốn tiếp tục đăng ký?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: '<i class="las la-check fs-3"></i> Đồng ý',
            cancelButtonText: '<i class="las la-times fs-3"></i> Hủy',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          })
          .then(async result => {
            if (result.value) {
              await this.creditRegistration(data)
            }
          })
      } else {
        const response = await this.checkOverMinCredit({
          subjectId: data.subjectId,
          courseSemesterId: data.courseSemesterId,
          studentCreditClass: {
            studentClassId: this.studentClassId,
            creditClassId: data.creditClassId,
          },
        })
        if (response.check) {
          await this.creditRegistration(data)
        } else {
          this.$swal
            .fire({
              title: 'Học viên đã đăng ký đủ số lượng tín chỉ cho nhóm học phần này!',
              text: 'Bạn muốn tiếp tục đăng ký?',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: '<i class="las la-check fs-3"></i> Đồng ý',
              cancelButtonText: '<i class="las la-times fs-3"></i> Hủy',
              customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ml-1',
              },
              buttonsStyling: false,
            })
            .then(async result => {
              if (result.value) {
                await this.creditRegistration(data)
              }
            })
        }
      }
    },
    async creditRegistration(data) {
      this.isLoading = true
      try {
        const response = await this.createStudentRegisterSubject({
          subjectId: data.subjectId,
          courseSemesterId: data.courseSemesterId,
          studentCreditClass: {
            studentClassId: this.studentClassId,
            creditClassId: data.creditClassId,
          },
          studentId: this.user.studentId,
          accountId: this.user.accountId,
          orgCode: this.user.orgCode,
        })
        if (response) {
          const { isSuccessful, message } = response
          if (isSuccessful) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            // await this.getDataSourcesFromStore()
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          }
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onSearchMainData() {
      await this.getDataSourcesFromStore()
    },
    onDeleteStudentRegisterSubject(studentCreditClassId) {
      this.$swal({
        title: 'Bạn chắc chắn muốn xóa dữ liệu?',
        text: 'Bạn sẽ không thể khôi phục lại dữ liệu này!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="las la-check fs-3"></i> Đồng ý',
        cancelButtonText: '<i class="las la-times fs-3"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.isLoading = true
          try {
            const response = await this.deleteStudentRegisteredSubject({ id: studentCreditClassId, studentId: this.user.studentId })
            if (response) {
              const { isSuccessful, message } = response
              if (isSuccessful) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                try {
                  await this.getDataSourcesFromStore()
                } catch (e) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: `[${e.code}] ${e.message}`,
                      icon: 'XCircleIcon',
                      variant: 'danger',
                    },
                  })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.isLoading = false
          }
        }
      })
    },
    onMessageKafka() {
      // Khi nhận được tin nhắn từ Kafka

    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
.list-schedule ul li {
  list-style: none;
  padding: 2px;
}
.list-schedule ul {
  padding: 0;
}
</style>
